import React from "react";
import { connect } from "react-redux";
import { Close } from "@mui/icons-material/";

const ModalAnnouncement = (props) => {
  return (
    <div className="w-full bg-success-600">
      <div className="text-center position-relative">
        <p className="font-xs md-font-sm text-white py-4">
          NEW LMS 3.0 Beta Version Available Now! Try it out at{" "}
          <a
            className="text-warning"
            href="https://academy.hiatlaz.com"
            target="_blank"
          >
            https://academy.hiatlaz.com
          </a>{" "}
          and sign up now! For more information: WhatsApp{" "}
          <a
            className="text-warning"
            href="https://wa.me/6285163054033"
            target="_blank"
          >
            +62 851-6305-4033
          </a>{" "}
          .
        </p>
        {/* <div
          className="position-absolute text-white cursor-pointer text-right"
          style={{ zIndex: "90", top: "10%", right: "20px" }}
        >
          <Close className="fs-20" />
        </div> */}
      </div>
    </div>
  );
};

export default connect()(ModalAnnouncement);
